import isEqual from 'lodash/isEqual';
import { ReportSection } from './report-section.enum';
import { ReportType } from './report-type.enum';
import { ReportViewModelSection } from './report-view-model-section.enum';

export enum ReportSectionPlacementType {
  Static = 'Static',
  Configurable = 'Configurable'
}

export enum ReportSectionConfigurationControlType {
  Date = 'Date',
  BigNumber = 'BigNumber',
  Decimal = 'Decimal',
  Number = 'Number',
  Text = 'Text',
  Custom = 'Custom'
}

export interface ReportSectionDataSection {
  name: string;
  displayName: string;
  unit?: number;
  isSelected: boolean;
  canConfigure: boolean;
  applicableReportTypes?: ReportType[];
  subSections?: ReportSectionDataSection[];
  canReorderSubSections?: boolean;
  property?: string;
  type?: ReportSectionConfigurationControlType;
}

export interface ReportSectionConfigurationControl {
  name: string;
  displayName: string;
  applicableDataSection?: string;
  type: string;
  options?: (number | string)[];
  defaultOption?: number | string | boolean;
}

export interface ReportSectionConfiguration {
  typeName: string;
  name: string;
  reportSection: ReportSection;
  displayName: string;
  applicableReportTypes: ReportType[];
  appliedReportType?: ReportType;
  placementType: ReportSectionPlacementType;
  resizable: boolean;
  minSize: number;
  maxSize: number;
  defaultSize: number;
  requirePostDataInput: boolean;
  availableDataSections: ReportSectionDataSection[];
  configurationControls?: ReportSectionConfigurationControl[];
  totalDataSectionsCount: number;
  selectedDataSectionsCount: number;
  reportViewModelSections: ReportViewModelSection[];
  availableDataSectionsCount?: number;
  toJSON?: () => ReportSectionConfiguration;
}

export abstract class ReportSectionConfigurationBase implements ReportSectionConfiguration {
  abstract typeName: string;
  abstract name: string;
  abstract reportSection: ReportSection;
  abstract displayName: string;
  abstract applicableReportTypes: ReportType[];
  private _appliedReportType?: ReportType;
  private _totalDataSectionsCount?: number;

  get appliedReportType(): ReportType | undefined {
    return this._appliedReportType;
  }
  set appliedReportType(value: ReportType) {
    if (!this.applicableReportTypes.includes(value)) {
      throw new Error('Report Type not supported');
    }
    this._appliedReportType = value;
  }

  get totalDataSectionsCount(): number {
    return this.availableDataSections.length;
  }

  set totalDataSectionsCount(value: number) {
    this._totalDataSectionsCount = value;
  }

  readonly placementType: ReportSectionPlacementType = ReportSectionPlacementType.Configurable;
  get resizable(): boolean {
    return this.minSize < this.maxSize;
  }
  abstract minSize: number;
  abstract maxSize: number;
  abstract defaultSize: number;
  get requirePostDataInput(): boolean {
    return false;
  }
  abstract availableDataSections: ReportSectionDataSection[];
  configurationControls: ReportSectionConfigurationControl[] = [];
  abstract reportViewModelSections: ReportViewModelSection[];

  get selectedDataSectionsCount(): number {
    return this.availableDataSections.filter(i => i.isSelected).length;
  }

  get availableDataSectionsCount(): number {
    return this.availableDataSections.length;
  }

  toJson(): string {
    return JSON.stringify({
      name: this.name,
      reportSection: this.reportSection,
      displayName: this.displayName,
      applicableReportTypes: this.applicableReportTypes,
      appliedReportType: this.appliedReportType,
      placementType: this.placementType,
      minSize: this.minSize,
      maxSize: this.maxSize,
      defaultSize: this.defaultSize,
      availableDataSections: this.availableDataSections,
      configurationControls: this.configurationControls,
      reportViewModelSections: this.reportViewModelSections,
      selectedDataSectionsCount: this.selectedDataSectionsCount,
      totalDataSectionsCount: this.totalDataSectionsCount
    });
  }

  equals(other: ReportSectionConfigurationBase): boolean {
    return (
      this.name === other.name &&
      this.reportSection === other.reportSection &&
      this.displayName === other.displayName &&
      isEqual(this.applicableReportTypes, other.applicableReportTypes) &&
      this.appliedReportType === other.appliedReportType &&
      this.placementType === other.placementType &&
      this.minSize === other.minSize &&
      this.maxSize === other.maxSize &&
      this.defaultSize === other.defaultSize &&
      isEqual(this.availableDataSections, other.availableDataSections) &&
      isEqual(this.configurationControls, other.configurationControls) &&
      isEqual(this.reportViewModelSections, other.reportViewModelSections)
    );
  }
}

export interface ReportSectionConfigurationWithOrder {
  order: number;
  isSelected: boolean;
  isActiveTemplate: boolean;
  width?: number;
  configClass: new () => ReportSectionConfigurationBase;
  config: ReportSectionConfigurationBase;
  equals(other: ReportSectionConfigurationWithOrder): boolean;
}

export class ReportSectionConfigurationWithOrderImpl implements ReportSectionConfigurationWithOrder {
  order: number;
  isSelected: boolean;
  isActiveTemplate: boolean;
  configClass: new () => ReportSectionConfigurationBase;
  config: ReportSectionConfigurationBase;
  width: number;
  equals(other: ReportSectionConfigurationWithOrder): boolean {
    return (
      this.order === other.order &&
      this.isSelected === other.isSelected &&
      this.isActiveTemplate === other.isActiveTemplate &&
      this.configClass === other.configClass &&
      this.config.equals(other.config) &&
      this.width === other.width
    );
  }

  constructor(order: number, isSelected: boolean, configClass: new () => ReportSectionConfigurationBase) {
    this.order = order;
    this.isSelected = isSelected;
    this.isActiveTemplate = false;
    this.configClass = configClass;
    this.config = new configClass();
    this.width = this.config.defaultSize;
  }
}
