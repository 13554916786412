import { ProfileType } from '@capital-access/profiles/common';
import { ConditionalReportSections, ReportSection, SectionSelectorListItem } from '../../core/models/report-sections';

// TODO: refactor
export function getConditionalReportSections(args: {
  historicalOwnership: boolean;
  sustainabilityFitMetric: boolean;
  primaryContactBiography: boolean;
  participantBiography: boolean;
  combinedOwnership: boolean;
}): ConditionalReportSections {
  return {
    [ReportSection.HistoricalOwnership]: args.historicalOwnership,
    [ReportSection.SustainabilityFitMetric]: args.sustainabilityFitMetric,
    [ReportSection.PrimaryContactBiography]: args.primaryContactBiography,
    [ReportSection.ParticipantBiography]: args.participantBiography,
    [ReportSection.CombinedOwnership]: args.combinedOwnership
  };
}

const DEFAULT_CONTACT_SECTIONS: SectionSelectorListItem[] = [
  {
    id: ReportSection.InstitutionDetails,
    isSelected: true,
    localizationKey: 'primaryInstitutionDetailsSection'
  },
  {
    id: ReportSection.InstitutionInvestmentApproach,
    isSelected: true,
    localizationKey: 'primaryInstitutionInvestmentApproachSection'
  },
  { id: ReportSection.ContactBiography, isSelected: true, localizationKey: 'contactBiographySection' },
  { id: ReportSection.CustomFields, isSelected: true, localizationKey: 'customFieldsSection' },
  { id: ReportSection.FundsSummary, isSelected: true, localizationKey: 'fundsSummarySection' },
  {
    id: ReportSection.Targeting,
    isSelected: true,
    localizationKey: 'targetingSection'
  },
  {
    id: ReportSection.FinancialHighlights,
    isSelected: true,
    localizationKey: 'ownershipActivitiesSection'
  },
  {
    id: ReportSection.EquityPortfolio,
    isSelected: true,
    localizationKey: 'primaryInstitutionEquityPortfolioSection'
  },
  {
    id: ReportSection.PeerOwnership,
    isSelected: true,
    localizationKey: 'primaryInstitutionPeerOwnershipSection'
  },
  {
    id: ReportSection.SuitabilityFactors,
    isSelected: true,
    localizationKey: 'suitabilityFactors'
  },
  {
    id: ReportSection.HistoricalOwnership,
    isSelected: true,
    localizationKey: 'primaryInstitutionHistoricalOwnershipSection'
  },
  { id: ReportSection.ActivityHistory, isSelected: true, localizationKey: 'activityHistorySection' },
  { id: ReportSection.SustainabilityFocus, isSelected: true, localizationKey: 'sustainabilityFocusSection' }
];

const DEFAULT_INVESTOR_SECTIONS: SectionSelectorListItem[] = [
  { id: ReportSection.InstitutionDetails, isSelected: true, localizationKey: 'institutionDetailsSection' },
  {
    id: ReportSection.InstitutionInvestmentApproach,
    isSelected: true,
    localizationKey: 'institutionInvestmentApproachSection'
  },
  {
    id: ReportSection.PrimaryContactBiography,
    isSelected: true,
    localizationKey: 'primaryContactBiographySection'
  },
  { id: ReportSection.ParticipantBiography, isSelected: true, localizationKey: 'participantBiographySection' },
  { id: ReportSection.CustomFields, isSelected: true, localizationKey: 'customFieldsSection' },
  { id: ReportSection.FundsSummary, isSelected: true, localizationKey: 'fundsSummarySection' },
  {
    id: ReportSection.Targeting,
    isSelected: true,
    localizationKey: 'targetingSection'
  },
  {
    id: ReportSection.FinancialHighlights,
    isSelected: true,
    localizationKey: 'ownershipActivitiesSection'
  },
  {
    id: ReportSection.CombinedOwnership,
    isSelected: true,
    localizationKey: 'combinedOwnershipSection'
  },
  { id: ReportSection.EquityPortfolio, isSelected: true, localizationKey: 'institutionEquityPortfolioSection' },
  { id: ReportSection.PeerOwnership, isSelected: true, localizationKey: 'institutionPeerOwnershipSection' },
  { id: ReportSection.SuitabilityFactors, isSelected: true, localizationKey: 'suitabilityFactors' },
  { id: ReportSection.HistoricalOwnership, isSelected: true, localizationKey: 'historicalOwnershipSection' },
  { id: ReportSection.ActivityHistory, isSelected: true, localizationKey: 'activityHistorySection' },
  { id: ReportSection.SustainabilityFocus, isSelected: true, localizationKey: 'sustainabilityFocusSection' },
  { id: ReportSection.SustainabilityFitMetric, isSelected: true, localizationKey: 'sustainabilityFitMetricSection' }
];

const DEFAULT_ONE_PAGER_CONTACT_SECTIONS: SectionSelectorListItem[] = [
  {
    id: ReportSection.ContactBiography,
    isSelected: true,
    localizationKey: 'contactBiographySection'
  },
  {
    id: ReportSection.InstitutionDetails,
    isSelected: true,
    localizationKey: 'primaryInstitutionDetailsSection'
  },
  {
    id: ReportSection.InstitutionInvestmentApproach,
    isSelected: true,
    localizationKey: 'primaryInstitutionInvestmentApproachSection'
  },
  { id: ReportSection.Ownership, isSelected: true, localizationKey: 'ownershipSection' },
  {
    id: ReportSection.TargetingEquityPortfolio,
    isSelected: true,
    localizationKey: 'targetingEquityPortfolioSection'
  },
  { id: ReportSection.FundsSummary, isSelected: true, localizationKey: 'fundsSummarySection' },
  { id: ReportSection.ActivityHistory, isSelected: true, localizationKey: 'onePagerActivityHistorySection' }
];

const DEFAULT_ONE_PAGER_INVESTOR_SECTIONS: SectionSelectorListItem[] = [
  { id: ReportSection.InstitutionDetails, isSelected: true, localizationKey: 'institutionDetailsSection' },
  {
    id: ReportSection.InstitutionInvestmentApproach,
    isSelected: true,
    localizationKey: 'institutionInvestmentApproachSection'
  },
  { id: ReportSection.Ownership, isSelected: true, localizationKey: 'ownershipSection' },
  {
    id: ReportSection.HistoricalPeerOwnership,
    isSelected: true,
    localizationKey: 'historicalPeerOwnership'
  },
  {
    id: ReportSection.TargetingEquityPortfolio,
    isSelected: true,
    localizationKey: 'targetingEquityPortfolioSection'
  },
  { id: ReportSection.ActivityHistory, isSelected: true, localizationKey: 'onePagerActivityHistorySection' }
];

export function getDefaultSections(
  profileType: ProfileType,
  reportLayout: ProfileReportLayout = ProfileReportLayout.Detailed
): SectionSelectorListItem[] {
  switch (reportLayout) {
    case ProfileReportLayout.OnePager: {
      return getOnePagerDefaultSections(profileType);
    }
    default:
      return profileType == ProfileType.Contact ? DEFAULT_CONTACT_SECTIONS : DEFAULT_INVESTOR_SECTIONS;
  }
}

function getOnePagerDefaultSections(profileType: ProfileType): SectionSelectorListItem[] {
  return profileType == ProfileType.Contact ? DEFAULT_ONE_PAGER_CONTACT_SECTIONS : DEFAULT_ONE_PAGER_INVESTOR_SECTIONS;
}

export enum ProfileReportLayout {
  OnePager = 'OnePager',
  Detailed = 'Detailed'
}

export const DEFAULT_LAYOUT = ProfileReportLayout.OnePager;
